import React from "react";
import Grid from "@mui/material/Grid";
import DictInput from "../controls/DictInput";
import Resources from "../../Resources";
import {DateInput, NumberInput, BooleanInput} from "react-admin";
import { useWatch } from 'react-hook-form';
import PropTypes from "prop-types";
import DictArrayInput from "../controls/DictArrayInput";
import {FormattedNumberInput} from "../controls/FormattedNumberInput";

function LoanApplicationCommonDataEditForm(props) {
    const {disableBorrowerConfirmIfInitiatorIsEmpty} = props;
    const initiatorLogin = useWatch({ name: 'initiatorLogin' });

    return (
        <Grid container spacing={1}>
            <Grid item xs={4}>
                <DateInput label="Targeted Closure" required source="targetedClosure" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Borrower Type" source="borrowerTypeCode" required
                           reference={Resources.DICT_BORROWER_TYPES.name} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Deal Type" source="dealTypeCode" reference={Resources.DICT_DEAL_TYPES.name} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Sector" source="sectorCode" required
                           reference={Resources.DICT_SECTORS.name} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="irrFrom" label="Indicative Return From, %" required fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="irrTo" label="Indicative Return To, %" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <FormattedNumberInput source="revenue" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="irmmFrom" label="Returns From, Money Multiple" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <NumberInput source="irmmTo" label="Returns To, Money Multiple" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <FormattedNumberInput source="ebitda" label="EBITDA" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <FormattedNumberInput source="enterpriseValue" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Use of Funds" source="useOfFundsCode"
                                reference={Resources.DICT_USE_OF_FUNDS.name} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Loan Structures" source="loanStructureCode"
                                reference={Resources.DICT_LOAN_STRUCTURES.name} fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <DictInput label="Business Stages" source="businessStageCode"
                           reference={Resources.DICT_BUSINESS_STAGES.name} fullWidth/>
            </Grid>
            {/*<Grid item xs={4}></Grid>*/}
            <Grid item xs={7}>
                <BooleanInput source="isBorrowerConfirmationRequired"
                              label="Confirm the list of Debt Investors with the borrower?"
                              fullWidth disabled={disableBorrowerConfirmIfInitiatorIsEmpty && !initiatorLogin} />
            </Grid>
            <Grid item xs={5}>
                <BooleanInput source="isWatermarkRequired"
                              label="Is watermark required on documents?"
                              fullWidth />
            </Grid>
            <Grid item xs={7}>
                <BooleanInput source="autoVerifyOffers"
                              label="Automatically verify counterparty offers?"
                              fullWidth />
            </Grid>
        </Grid>
    )
}

export default LoanApplicationCommonDataEditForm;

LoanApplicationCommonDataEditForm.propTypes = {
    disableBorrowerConfirmIfInitiatorIsEmpty: PropTypes.bool,
}