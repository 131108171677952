import PropTypes from 'prop-types'
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Button, useDataProvider, useNotify} from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import React, {useEffect, useRef, useState} from "react";
import {Grid, LinearProgress} from "@mui/material";
import {onError} from "../../common/utils";

const DownloadZipDialog = (props) => {
    const {close, open, urlToInitiate, urlToGetStatus, urlToDownload} = props;
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const getStatusTimeout = 2000;
    const [message, setMessage] = useState("Initiating...")
    const [percentage, setPercentage] = useState(0)
    const [linkToDownload, setLinkToDownload] = useState(null)
    const linkToGetStatus = useRef(null)
    const started = useRef(false)
    const timerId = useRef(null)
    const [done, setDone] = useState(false)

    useEffect(() => {

    }, [linkToGetStatus]);
    function progress() {
        // console.log("linkToGetStatus", linkToGetStatus)
        if (!linkToGetStatus) {
            return;
        }

        dataProvider.doAction(linkToGetStatus.current, {
            method: 'GET',
        }).then(({data}) => {
            /**
             * Long id;
             * Long documentId;
             * GenerationStatus statusCode; // SUBMITTED, PROCESSING, FAILED, DONE
             * Short readiness;
             * OffsetDateTime createdDate;
             * String errorMessage;
             * String createdBy;
             */
            // console.log("data status", data);
            if (data.statusCode === 'FAILED') {
                notify(`Archive can be created. Error is ${data.errorMessage}`, {type: 'error', autoHideDuration: 5000});
            } else if (data.statusCode === 'DONE') {
                setPercentage(100)
                setDone(true)
                setLinkToDownload(urlToDownload.replace("{id}", data.id))
                setMessage("Click 'Download archive' button to save archive")
            } else {
                setPercentage(data.readiness)
                timerId.current = setTimeout(progress, getStatusTimeout)
            }
        }).catch((err) => {
            onError(err, notify)
        });
    }

    function startDownload() {
        if (started.current)
            return;

        started.current = true
        setMessage("Preparing archive...")
        dataProvider.doAction(urlToInitiate, {
            method: 'POST',
        }).then(({data}) => {
            /**
             * Long id;
             * Long documentId;
             * GenerationStatus statusCode; // SUBMITTED, PROCESSING, FAILED, DONE
             * Short readiness;
             * OffsetDateTime createdDate;
             * String errorMessage;
             * String createdBy;
             */
            linkToGetStatus.current = urlToGetStatus.replace("{id}", data.id);
            // console.log("data init", data, linkToGetStatus);
            timerId.current = setTimeout(progress, getStatusTimeout)
        }).catch((err) => {
            onError(err, notify)
        });
    }

    useEffect(() => {
        startDownload()

        return () => {
            // console.log("clearing...")
            //Clearing a timeout
            clearTimeout(timerId.current);
        };
    }, [urlToInitiate]);

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        close();
    }

    function downloadZip() {
        window.location.href = `/api/v1${linkToDownload}`
        close();
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="lg" disableEscapeKeyDown >
            <DialogTitle>Export Dataroom as archive</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {message}
                    </Grid>
                    <Grid item xs={12}>
                        <LinearProgress value={percentage} variant="determinate" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close} label="Cancel"/>
                <Button disabled={!done} label="Download archive" onClick={downloadZip} color="secondary" variant="outlined"/>
            </DialogActions>
        </Dialog>
    );
}

export default DownloadZipDialog;

DownloadZipDialog.propTypes = {
    close: PropTypes.func.isRequired,
    urlToInitiate: PropTypes.string.isRequired,
    urlToGetStatus: PropTypes.string.isRequired,
    urlToDownload: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
}