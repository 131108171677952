import PropTypes from 'prop-types'
import {Datagrid, EditButton, TextField} from "react-admin";
import {PartyField} from "../controls/PartyField";
import * as React from "react";
import EnumDictField from "../controls/EnumDictField";
import {PartyRelations} from "../dicts/PartyPartyRelEnums";
import Resources from "../../Resources";

function PartyPartyRelDatagrid(props) {
    let showFromEntity;
    let showToEntity;
    if (!props.showFromEntity && !props.showBothEntities && !props.showToEntity) {
        showFromEntity = true;
        showToEntity = true;
    } else if (props.showBothEntities) {
        showFromEntity = true;
        showToEntity = true;
    } else {
        showFromEntity = props.showFromEntity;
        showToEntity = props.showToEntity;
    }
    return (
        <Datagrid rowClick={false}>
            <TextField source="id" />
            {showFromEntity && <PartyField source="fromId" partyTypeSource="fromType" />}
            {showToEntity && <PartyField source="toId" partyTypeSource="toType" />}
            <EnumDictField enum={PartyRelations} source="relationType" />
            <TextField source="relationTitle" />
            {/*<EditButton resource={Resources.PARTY_PARTY_REL.name} />*/}
        </Datagrid>
    );
}

export default PartyPartyRelDatagrid;

PartyPartyRelDatagrid.propTypes = {
  showBothEntities: PropTypes.bool,
  showFromEntity: PropTypes.bool,
  showToEntity: PropTypes.bool
}