import PropTypes from 'prop-types'
import React from "react";
import Resources from "../../Resources";
import {AutocompleteArrayInput, AutocompleteInput, useGetList} from "react-admin";
import {CurrencyType} from "../dicts/ExchangeEnums";

export function CurrencyInput(props) {
    const {currencyTypeCode, doNotPrintCurrencyType, reference, name, source, excludes, filter, sx, multiple} = props;
    const {data, isLoading, error} =
        useGetList(reference || Resources.DICT_CURRENCIES.name, {sort: {field: 'code', order: 'ASC'}, filter});

    if (isLoading) {
        return <>Loading... </>;
    }
    if (error) {
        return <p>ERROR</p>;
    }

    let choices;
    if (currencyTypeCode) {
        const filter = {};
        if (typeof (currencyTypeCode) === 'string') {
            filter[currencyTypeCode] = currencyTypeCode;
        } else if (Array.isArray(currencyTypeCode)) {
            currencyTypeCode.forEach(e => filter[e] = e);
        }
        choices = data.filter(v => !!filter[v.currencyType]);
    } else {
        choices = data;
    }

    if (excludes) {
        const filter = {}
        excludes.forEach(e => filter[e] = e);
        choices = choices.filter(v => !filter[v.code])
    }

    const currencyOptionRenderer = choice => {
        return doNotPrintCurrencyType
            ? `${choice.code} - ${choice.name}`
            : `${CurrencyType[choice.currencyType].label}: ${choice.code} - ${choice.name}`;
    }

    return (<>
            {!!multiple && <AutocompleteArrayInput
                choices={choices} name={name} source={source}
                label={props.label} onChange={props.onChange} optionValue="code"
                optionText={currencyOptionRenderer}
                fullWidth={props.fullWidth} required={props.required} disabled={props.disabled}
                validate={props.validate}
                sx={sx}
            />}
            {!multiple && <AutocompleteInput
                    choices={choices} name={name} source={source}
                    label={props.label} onChange={props.onChange} optionValue="code"
                    optionText={currencyOptionRenderer}
                    fullWidth={props.fullWidth} required={props.required} disabled={props.disabled}
                    validate={props.validate}
                    sx={sx}
                />
            }
        </>
    )
        ;
}

CurrencyInput.defaultProps = {
    doNotPrintCurrencyType: true
}

CurrencyInput.propTypes = {
    currencyTypeCode: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    filter: PropTypes.object,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    source: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    doNotPrintCurrencyType: PropTypes.bool,
    reference: PropTypes.string,
    excludes: PropTypes.arrayOf(PropTypes.string),
    validate: PropTypes.func,
    sx: PropTypes.object,
    multiple: PropTypes.bool,
}
